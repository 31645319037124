import React from 'react'
import cx from 'classnames'

export default function ({ children, icon = 'empty', onClick, disabled }) {
  let jsxIcon = null
  let msg
  if (icon === 'phone') {
    jsxIcon = <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='currentColor' stroke='currentColor' strokeWidth='1' strokeLinecap='round' strokeLinejoin='round' className='feather feather-phone'><path d='M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z' /></svg>
    msg = children || 'Call your Senators'
  } else if (icon === 'twitter') {
    jsxIcon = <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='currentColor' stroke='currentColor' strokeWidth='1' strokeLinecap='round' strokeLinejoin='round' className='feather feather-twitter'><path d='M23 3a10.9 10.9 0 0 1-3.14 1.53 4.48 4.48 0 0 0-7.86 3v1A10.66 10.66 0 0 1 3 4s-4 9 5 13a11.64 11.64 0 0 1-7 2c9 5 20 0 20-11.5a4.5 4.5 0 0 0-.08-.83A7.72 7.72 0 0 0 23 3z' /></svg>
    msg = children || 'Tweet this'
  } else if (icon === 'facebook') {
    jsxIcon = <svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='currentColor' stroke='currentColor' strokeWidth='1' strokeLinecap='round' strokeLinejoin='round' className='feather feather-facebook'><path d='M18 2h-3a5 5 0 0 0-5 5v3H7v4h3v8h4v-8h3l1-4h-4V7a1 1 0 0 1 1-1h3z' /></svg>
    msg = children || 'Share on Facebook'
  } else if (icon === 'sign') {
    jsxIcon = <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" className="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7" ></path><path fill="currentColor" d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg>
    msg = children || 'Sign the Petition'
  }
  return (
    <button
      className={cx('btn-wrpr', `${icon}-btn-wrpr`)}
      onClick={onClick}
      disabled={disabled}
    >
      { jsxIcon }
      <span>{ msg }</span>
    </button>
  )
}
