import React, { useState, useRef, useEffect } from 'react'
import { isFormValid } from '../util/'
import './AKForm.scss'

// https://github.com/demand-progress/freedomfromfb-www/blob/master/js/containers/ActionForm.jsx
const sendFormToActionKit = fields => {
  // iFrame
  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'
  iframe.setAttribute('name', 'actionkit-iframe')
  document
    .body
    .appendChild(iframe)

  // Form
  const form = document.createElement('form')
  form.style.display = 'none'
  form.setAttribute('action', process.env.REACT_APP_ACTION_KIT_URL)
  form.setAttribute('method', 'post')
  form.setAttribute('target', 'actionkit-iframe')
  document
    .body
    .appendChild(form)

  Object
    .keys(fields)
    .forEach(function (key) {
      const input = document.createElement('input')
      input.type = 'hidden'
      input.name = key
      input.value = fields[key]
      form.appendChild(input)
    })

  form.submit()
  return true
}

export default function ({ slug, onChange, onPetitionSent, onFocus, isFocused }) {
  if (!slug) return null
  const [form, setForm] = useState({
    name: '',
    email: '',
    zip: ''
  })
  const [disabled, setDisabled] = useState(true)
  const firstFieldRef = useRef(null)
  useEffect(() => {
    if (isFocused && firstFieldRef.current) {
      firstFieldRef.current.focus()
    }
  }, [isFocused])
  const formUpdate = field => {
    return evt => {
      const obj = {}
      obj[field] = evt.target.value
      const st8 = Object.assign({}, form, obj)
      setForm(st8)
      if (onChange) {
        onChange(st8)
      }
      setDisabled(!isFormValid(st8))
    }
  }
  const submitForm = () => {
    const fields = Object.assign({}, {
      action_user_agent: navigator.userAgent,
      country: 'United States',
      form_name: 'act-petition',
      js: 1,
      opt_in: 1,
      page: slug,
      source: 'website'
    }, form)
    if (sendFormToActionKit(fields)) {
      onPetitionSent(true)
    }
  }
  return <div className='ak-form' >
    <input type='text' ref={firstFieldRef} onFocus={onFocus} value={form.name} onChange={formUpdate('name')} placeholder='Name' />
    <input type='email' onFocus={onFocus} value={form.email} onChange={formUpdate('email')} placeholder='Email' />
    <input type='text' onFocus={onFocus} value={form.zip} onChange={formUpdate('zip')} placeholder='Zip' />
    <button className='btn btn-primary' onClick={submitForm} disabled={disabled}>Add My Name</button>
  </div>
}
