
import { path } from 'ramda'
import { slugify } from '../api'
import CC from '../config/constants'

/**
 * @return bool Whether the form fields are valid
 */
export function isFormValid (fields) {
  // check disabled - TODO: a bit more :)
  if (fields.email === '') {
    return false
  }
  return true
}

/**
 * @return string of number split with commas
 */
export function formatNumber (n) {
  // to test bigger numbers: n *= Math.round(Math.random() * 10000)
  return n.toLocaleString()
}

export function throttle (delay, fn) {
  let timer = null
  return () => {
    clearTimeout(timer)
    timer = setTimeout(fn, delay)
  }
}

export function getHeadshot (site, minDims) {
  console.info('getHeadshot', minDims)
  // prefer the url for the image that is the smallest dimensions that are still
  // large enough to fill the screen
  const { w: W, h: H } = minDims
  const sizes = getHeadshotSizes({ site })

  // get the obj keys that point to url's
  const urls = Object.entries(sizes).filter(kv => typeof kv[1] === 'string' && kv[1].match(/^https?:\/\//))

  let fits = null

  urls.forEach(([name, url]) => {
    const w = sizes[`${name}-width`]
    const h = sizes[`${name}-height`]
    if (!w || !h) return // missing dimension info
    if (w > W && h > H) {
      // is big enough to cover the screen
      if (!fits || (w < fits.w && h < fits.h)) {
        // is the smallest so far that will still cover the screen
        fits = { w, h, name, url }
      }
    }
  })

  return fits
    ? { url: fits.url, position: CC.DEFAULT_HEADSHOT_POSITION }
    : getHeadshotLargest({ sizes })
}

export function getHeadshotLargest ({ site = null, sizes = null }) {
  // choose a backup based on known keys
  const keys = ['2048x2048', 'large', 'medium_large', 'medium', 'thumbnail']

  sizes = sizes || getHeadshotSizes(site)

  if (sizes) {
    for (let i = 0; i < keys.length; i++) {
      if (sizes[keys[i]]) {
        return { url: sizes[keys[i]], position: CC.DEFAULT_HEADSHOT_POSITION }
      }
    }
  }

  return { url: '', position: '' } // none found
}

export function getHeadshotForSize (site, size) {
  const sizes = getHeadshotSizes(site) || {}
  return sizes[size]
}

export function getHeadshotSizes (site) {
  return path(['headshot', 'sizes'], site || {})
}

export function getPersonLink (person) {
  if (person.domain) {
    const domain = person.domain.split(',')
    if (process.env.NODE_ENV === 'development') {
      const local = domain.find(d => d.match(/localhost/))
      if (local) return 'http://' + local
    }
    for (let i = 0; i < domain.length; i++) {
      if (domain[i]) return 'https://' + domain[i]
    }
  }
  return `/who-were-watching/${slugify(person.name)}`
}

export function specialPage (pathname) {
  if (CC.PAGES.includes(pathname)) {
    return pathname.replace(/^\//, '')
  }
}
