
import React, { useState, useEffect } from 'react'
import { fetchProgress } from '../api/'
import { formatNumber } from '../util/'
import './SignatureCount.scss'

export default function ({ slug, addCount = 0 }) {
  const [count, setCount] = useState(false)

  useEffect(() => {
    ;(async () => {
      if (count !== false) return // set it to false if you want to fetch the count again

      const c = await fetchProgress({ slug })
      const theCount = parseInt(addCount || 0, 10) + c.total.actions
      if (c) setCount(theCount)
    })()
  }, [count, addCount, slug])

  if (!count || count < 100000) return null

  return <div className='sign-count'><span>{ formatNumber(count) }</span> signatures so far!</div>
}
