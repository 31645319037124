import React, { useEffect, useState } from 'react'
import { useRouteMatch } from 'react-router-dom'
import { fetchCallPower } from '../api'
import ActionButton from './ActionButton'
import SafeSpan from './SafeSpan'
import TweetButton from './TweetButton'
import cn from 'classnames'

export default function ({ site, zip, onChange }) {
  const match = useRouteMatch('/:page')
  const { page } = match ? match.params : { page: undefined }
  const skipCall = !site.callpower_id
  const [doneWith, setDoneWith] = useState('') // which step have we finished
  const [phone, setPhone] = useState('')
  const [step, setStep] = useState(chooseStep({ page, doneWith, skipCall }))

  useEffect(() => {
    const newStep = chooseStep({ page, doneWith, skipCall })
    setStep(newStep)
    if (onChange) {
      onChange(newStep)
    }
  }, [doneWith])

  const fbPopup = () => {
    var width = 550
    var height = 420
    var left = window.screen.width / 2 - width / 2
    var top = window.screen.height / 2 - height / 2
    const host = /localhost/.test(window.location.host) ? 'nocorporatecabinet.com' : window.location.host
    const url = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent('https://' + host)}`
    window.open(url, null, 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top)
  }

  // console.log('AfterSigning', site.callpower_id, { match, page, doneWith, step })

  function getTextContent (step, skipCall = false) {
    if (step === 'thanks') {
      return <div className='thanks-wrap'>
        <h2 className='thanks'>Thanks for taking action!</h2>
        <h4 className='big'>Now will you take the next step to keep corporate interests out of the Biden administration?</h4>
        <p className='big'>Enter your phone number below and we’ll call you and connect you directly with key decisionmakers.</p>
      </div>
    } else if (step === 'calling') {
      return (
        <div className='script-wrap'>
          <h2 className='big'>We're calling you now.</h2>
          <h4 className='big'>Here's what you can say:</h4>
          <div className='script'>
            <SafeSpan content={site.call_script} />
          </div>
          <div className='action-wrpr'>
            <ActionButton icon='phone' onClick={() => setDoneWith('calling')} >
              I'm Done Calling!
            </ActionButton>
          </div>
        </div>
      )
    } else if (step === 'social') {
      return (
        <>
          <h2 className='big'>Help spread the word</h2>
          <h4 className='big'>Amplify your impact</h4>
        </>
      )
    }
  }

  if (step === 'social') {
    return (
      <div className='inner-wrap'>
        {getTextContent('social', skipCall)}
        <div className='action-wrpr'>
          <TweetButton site={site} zip={zip} />
          <ActionButton icon='facebook' onClick={fbPopup} />
        </div>
      </div>
    )
  } else if (step === 'calling') {
    return getTextContent('calling')
  } else {
    return (
      <div className={cn(page)}>
        {getTextContent('thanks')}
        <div className='form-wrap'>
          <input
            autoFocus
            type='text'
            placeholder='Enter your 10 digit phone number'
            value={phone}
            onChange={evt => {
              setPhone(evt.target.value.replace(/[^\d]/g, ''))
            }}
          />
          <div className='action-wrpr'>
            <ActionButton disabled={phone.length !== 10} icon='phone' onClick={() => {
              fetchCallPower(site.callpower_id, phone)
              setDoneWith('thanks')
            }}>
              Call Key decisionmakers 
            </ActionButton>
          </div>
        </div>
        <button className='link-btn' onClick={() => setDoneWith('calling')}> I can’t call, but I can tweet at key decisionmakers instead
        </button>
      </div>
    )
  }

  /**
   * STEPS => thanks, calling, social
   */
  function chooseStep ({ page, doneWith, skipCall }) {
    // TODO should we use router to navigate to social if we're skipping callpower?
    if (page === 'social' || doneWith === 'calling') {
      return 'social'
    }
    if (page === 'script' || doneWith === 'thanks') {
      return skipCall ? 'social' : 'calling'
    }
    return skipCall ? 'social' : 'thanks'
  }

}
