import React  from 'react'
import WpContent from './WpContent'
import './News.scss'
import SubscribeBtn from './SubscribeBtn'

export default function News () {
  return <div id='content' className='news'>
    <WpContent showTitle id={152282} afterHeader={<SubscribeBtn />} />
  </div>
}
