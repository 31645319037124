
import React from 'react'
import AKForm from './AKForm'
import SignatureCount from './SignatureCount'

export default function Petition ({ isOpen, site, setZip, onPetitionSent, onFocus }) {
  return (
    <>
      <h2 onClick={() => onFocus()}>Sign the Petition</h2>
      { site.petition_intro ? <h4>{ site.petition_intro }</h4> : null }
      <AKForm
        slug={site.actionkit_slug}
        onChange={form => setZip(form.zip.length === 5 ? form.zip : '')}
        onFocus={onFocus}
        onPetitionSent={onPetitionSent}
        isFocused={isOpen}
      />
      <SignatureCount
        slug={site.actionkit_slug}
        addCount={site.additional_signups}
      />
      <small>
        One or more of the participating organizations may email you about this or future campaigns.
      </small>
    </>
  )
}
