
import React, { useEffect, useState } from 'react'
import cx from 'classnames'
import './Nav.scss'
import {
  Link,
  NavLink,
} from 'react-router-dom'

export default function ({ site, onChange }) {
  const [open, setOpen] = useState(false)
  let nav = null
  useEffect(() => {
    onChange(open)
  }, [open])
  nav = (
    <>
      <button onClick={() => setOpen(!open)} className={cx('menu-toggle hamburger hamburger--squeeze', { 'is-active': open })} type='button'>
        <span className='hamburger-box'>
          <span className='hamburger-inner' />
        </span>
      </button>
      <ul id='nav-menu' className={cx({ open })}>
        <li><NavLink onClick={() => setOpen(false)} to='/' exact>Who We're Watching</NavLink></li>
        <li><NavLink onClick={() => setOpen(false)} to='/trope-tracker'>Trope Tracker</NavLink></li>
        <li><NavLink onClick={() => setOpen(false)} to='/news'>Blog</NavLink></li>
        <li><NavLink onClick={() => setOpen(false)} to='/about'>About</NavLink></li>
      </ul>
    </>
  )
  return (
    <nav className={cx({ open })}>
      <div className='brand'>
        <h1><Link to='/' >Hackwatch</Link></h1>
      </div>
      {nav}
    </nav>
  )
}
