
import { last } from 'ramda'
import fetchJsonp from 'fetch-jsonp'

const USE_LOCAL_STORAGE = false && /localhost/.test(window.location.href)

// export const API_URL = 'https://api.demandprogressaction.org'
// export const API_URL = 'http://localhost:8888/agencies'
export const API_URL = 'https://therevolvingdoorproject.org'

export const slugify = s => s.toLowerCase().trim().replace(/ /g, '-')

export const cleanWpJson = info => Object.assign({
  title: info.title.rendered,
  content: info.content.rendered
}, info.acf)

export async function fetchCallPower (campaignId, phone) {
  return fetch(`${process.env.REACT_APP_CALL_POWER_URL}?campaignId=${campaignId}&userPhone=${phone}`)
}

export async function fetchBidenCabinet () {
  if (window._site) {
    return window._site
  }
  try {
    const data = await fetchJson(`${API_URL}/wp-json/wp/v2/hackwatch?per_page=50`)
    const site = {
      peopleSites: data.map(cleanWpJson)
    }
    return site
  } catch (ex) {
    console.error(ex)
  }
}

export async function fetchProgress ({ slug }) {
  try {
    const url = `https://act.demandprogress.org/progress/${slug}`
    const c = _storage(url)
    if (c) return c
    const res = await fetchJsonp(url)
    return _storage(url, await res.json())
  } catch (ex) {
    console.warn('parsing failed', ex)
  }
}

export async function fetchNews () {
  try {
    return await fetchJson(process.env.REACT_APP_NEWS_URL)
  } catch (ex) {
    console.error(ex)
    return false
  }
}

async function fetchJson (url, status = 200) {
  const data = _storage(url)
  if (data) return data

  const res = await fetch(url)
  // console.log('res', res)
  if (!res || res.status !== status) {
    throw new Error(`Error fetching ${url}`)
  }

  return _storage(url, await res.json())
}

function _storage (key, val) {
  if (!USE_LOCAL_STORAGE) return val
  try {
    if (typeof val === 'undefined') {
      return JSON.parse(window.localStorage.getItem(key) || 'null')
    } else {
      window.localStorage.setItem(key, JSON.stringify(val))
      return val
    }
  } catch (ex) {
    console.warn('local storage', ex)
  }
}

export const getLast = name => last(name.split(' '))
export const alphaSort = (a, b) => getLast(a.name).localeCompare(getLast(b.name))