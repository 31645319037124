import React, { useState, useEffect, useRef } from 'react'
import { useInView } from 'react-intersection-observer'
import { useLocation } from 'react-router-dom'
import { Swipeable } from 'react-swipeable'
import Petition from './Petition'
import AfterSigning from './AfterSigning'
import './SideBar.scss'
import { specialPage } from '../util'
import cn from 'classnames'

const TAB_PEEK = 150 // how much of the closed tab to show
const BOTTOM_SPACE = 100 // space above content when we close the tab automatically

export default function ({ site, zip, setZip, onChange }) {
  const location = useLocation()
  const isSpecialPage = !!specialPage(location.pathname)
  const [petitionSent, setPetitionSent] = useState(isSpecialPage)
  const [tabFixed, setTabFixed] = useState(isSpecialPage)
  const [open, setOpen] = useState(true)
  const [forceOpen, setForceOpen] = useState(false)
  const [height, setHeight] = useState(0)
  const sidebarRef = useRef()
  const { ref, entry } = useInView({
    threshold: 0.01
  })
  useEffect(() => {
    if (!entry) return

    if (entry.isIntersecting) {
      setTabFixed(true)
    }
    if (open && tabFixed && !entry.isIntersecting && !forceOpen) {
      setOpen(false) // prefer to close it, unless the user has clicked it open
    }
  }, [entry, open, tabFixed, forceOpen])
  useEffect(() => {
    setHeight(sidebarRef.current.clientHeight)
  }, [tabFixed, open])
  const wrapStyle = {
    bottom: open ? 0 : -height + TAB_PEEK
  }
  const underStyle = {
    height: tabFixed ? height - BOTTOM_SPACE : 0,
    marginBottom: BOTTOM_SPACE
  }
  const toggleOpen = force => {
    const newOpen = typeof force === 'boolean' ? force : !open
    setOpen(newOpen)
    setForceOpen(newOpen)
    setTabFixed(true)
  }
  return (
    <>
      <div style={wrapStyle} className={cn('sidebar-wrap', { fixed: tabFixed, open })}>
        <Swipeable
          onSwipedUp={evt => {
            toggleOpen(true)
          }}
          onSwipedDown={evt => {
            toggleOpen(false)
          }}
        >
          <div id='sidebar' ref={sidebarRef}>
            <button className='tab-line' onClick={toggleOpen} />
            {
              !petitionSent
                ? <Petition isOpen={forceOpen} onFocus={() => toggleOpen(true)} site={site} setZip={setZip} onPetitionSent={setPetitionSent} />
                : <AfterSigning onChange={onChange} zip={zip} site={site} />
            }
          </div>
        </Swipeable>
      </div>
      <div style={underStyle} className='under-sidebar' ref={ref} />
    </>
  )
}
