import React, { useState, useEffect } from 'react'
import { getHeadshotLargest } from '../util/'
import { MAX_SKEW, MAX_ZOOM, MIN_CONTRAST, MAX_CONTRAST } from '../config/constants'
import './Headshot.scss'

export default function ({ site }) {
  const { url, position } = getHeadshotLargest({ site })

  if (!url) return null

  const [pos, setPos] = useState(0) // between 0 and 1, per viewport height
  const [zoom, setZoom] = useState(100)

  const handleScroll = () => {
    // max 1 is when the scroll-y is >= the viewport height
    setPos(Math.min(1, window.scrollY / window.innerHeight))
    setZoom(prevZoom => {
      return prevZoom + 0.1
    })
  }

  // useEffect(() => {
  //   window.addEventListener('scroll', handleScroll, { passive: true })
  //   return () => window.removeEventListener('scroll', handleScroll)
  // }, [])

  const style = {}
  if (url) style.backgroundImage = `url(${url})`
  let thePosition = position
  if (site.headshot_x && site.headshot_y) {
    thePosition = `${site.headshot_x}% -${site.headshot_y}px`
  }
  if (thePosition) style.backgroundPosition = thePosition
  // const zoom = 100 + 50 * pos
  style.left = -(zoom - 100) / 2 + 'vw'
  style.top = -(zoom - 100) / 2 + 'vh'
  style.width = zoom + 'vw'
  style.height = zoom + 'vh'
  // breaks in safari
  // style.transform = `perspective(100px) translateZ(${(pos * MAX_ZOOM)}px) skew(${(pos * MAX_SKEW)}deg)`
  // style.filter = `grayscale(100%) contrast(${(MIN_CONTRAST + (pos * (MAX_CONTRAST - MIN_CONTRAST)))}%)`
  return (
    <div id='headshot' style={style} />
  )
}
