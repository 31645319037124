import React  from 'react'

export default function SubscribeBtn () {
  return (
    <div className='btn-wrap subscribe-btn'>
      <div className='b-wrap'>
        <a className='btn' target='_blank' href='https://revolvingdoorproject.substack.com/subscribe?simple=true&next=https://revolvingdoorproject.substack.com/s/hack-watch'>subscribe for updates</a>
      </div>
    </div>
  )
}
