
const CC = {
  PETITION_TAB_CUTOFF: 0.1, // show/hide when petition is this visible
  DEFAULT_HEADSHOT_POSITION: '44.7% 50%',
  PAGES: [
    '/call',
    '/social',
    '/script'
  ]
}

export const MAX_SKEW = 1
export const MAX_ZOOM = 5
export const MIN_CONTRAST = 150
export const MAX_CONTRAST = 250

export default CC
