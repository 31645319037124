import { map, path, pipe, filter, pluck, propEq } from 'ramda'
import React, { useState, useEffect } from 'react'
import ActionButton from './ActionButton'

const StateSelect = ({ onChange }) => <select onChange={evt => {
  if (onChange) {
    onChange(evt.target.value)
  }
}} id='state_select' className='form-control'>
  <option value=''>Choose a state</option>
  <option value='35801'>Alabama</option>
  <option value='99501'>Alaska</option>
  <option value='85040'>Arizona</option>
  <option value='72201'>Arkansas</option>
  <option value='94610'>California</option>
  <option value='80230'>Colorado</option>
  <option value='06110'>Connecticut</option>
  <option value='19901'>Delaware</option>
  {/* <option value='20001'>District Of Columbia</option> */}
  <option value='32501'>Florida</option>
  <option value='30350'>Georgia</option>
  <option value='96814'>Hawaii</option>
  <option value='83254'>Idaho</option>
  <option value='60601'>Illinois</option>
  <option value='46201'>Indiana</option>
  <option value='52801'>Iowa</option>
  <option value='67210'>Kansas</option>
  <option value='41701'>Kentucky</option>
  <option value='70112'>Louisiana</option>
  <option value='04032'>Maine</option>
  <option value='20910'>Maryland</option>
  <option value='02110'>Massachusetts</option>
  <option value='49036'>Michigan</option>
  <option value='55805'>Minnesota</option>
  <option value='39530'>Mississippi</option>
  <option value='63101'>Missouri</option>
  <option value='59044'>Montana</option>
  <option value='68901'>Nebraska</option>
  <option value='89501'>Nevada</option>
  <option value='03217'>New Hampshire</option>
  <option value='07039'>New Jersey</option>
  <option value='87505'>New Mexico</option>
  <option value='10001'>New York</option>
  <option value='27565'>North Carolina</option>
  <option value='58282'>North Dakota</option>
  <option value='44101'>Ohio</option>
  <option value='74105'>Oklahoma</option>
  <option value='97201'>Oregon</option>
  <option value='15201'>Pennsylvania</option>
  <option value='02840'>Rhode Island</option>
  <option value='29020'>South Carolina</option>
  <option value='57401'>South Dakota</option>
  <option value='37201'>Tennessee</option>
  <option value='78701'>Texas</option>
  <option value='84321'>Utah</option>
  <option value='05751'>Vermont</option>
  <option value='24517'>Virginia</option>
  <option value='98004'>Washington</option>
  <option value='25813'>West Virginia</option>
  <option value='53210'>Wisconsin</option>
  <option value='82941'>Wyoming</option>
</select>

const TWEET_MAX = 280

export default function ({ site, zip }) {
  const tweets = pluck('tweet', site.tweets || [])
  if (tweets.length < 1) return null
  const [handles, setHandles] = useState([])
  const [internalZip, setInternalZip] = useState('')
  const [open, setOpen] = useState(false)
  const [fetchingZip, setFetchingZip] = useState(false)

  useEffect(() => {
    if (zip === '' && internalZip.length < 5) return

    setFetchingZip(true)
    fetch(`https://zip-2-rep-tweet.now.sh/?raw=true&zipcode=${internalZip || zip}`)
      .then(res => res.json())
      .then(pipe(
        filter(propEq('type', 'sen')),
        map(path(['social', 'twitter'])),
        map(h => `@${h}`),
        filter(Boolean),
        setHandles
      ))
      .then(() => setFetchingZip(false))
  }, [zip, internalZip])

  let targetsAdded = 0
  const randomTargets = (tweet, targets) => {
    if (!targets || targets.length === 0) return tweet
    if (targetsAdded > 1) return tweet
    const target = targets.splice(Math.floor(Math.random() * targets.length), 1)
    const regex = new RegExp(target, 'i')
    if (!regex.test(tweet)) {
      const newTweet = tweet + ' ' + target
      if (newTweet.length <= TWEET_MAX) {
        targetsAdded += 1
        return randomTargets(newTweet, targets)
      }
    }
    return randomTargets(tweet, targets)
  }

  const tweetPopup = () => {
    var width = 550
    var height = 420
    var left = window.screen.width / 2 - width / 2
    var top = window.screen.height / 2 - height / 2
    let tweet = tweets[(Math.floor(Math.random() * tweets.length))] + ' ' + handles.join(' ')
    if (site.tweet_append) {
      tweet += ' ' + site.tweet_append
    }
    if (site.tweet_targets) {
      const targets = pluck('target', site.tweet_targets)
      tweet = randomTargets(tweet, targets)
      targetsAdded = 0
    }
    const url = `http://twitter.com/intent/tweet?text=${encodeURIComponent(tweet).replace(/%20/g, '+')}`
    window.open(url, null, 'width=' + width + ',height=' + height + ',left=' + left + ',top=' + top)
  }
  const onClick = () => {
    if (handles.length > 0) {
      return tweetPopup()
    }
    setOpen(true)
  }
  let show = <ActionButton icon='twitter' onClick={onClick}>Tweet at Decisionmakers</ActionButton>
  if (open) {
    // we couldnt find the rep from the zip
    if (handles.length === 0 && internalZip.length === 5 && !fetchingZip) {
      show = <div id='states'>
        <label>
            Sorry, we couldn't find your reps. What state do you live in?
        </label>
        <StateSelect onChange={setInternalZip} />
      </div>
    } else {
      show = <div>
        <label>Enter your zip code below and we'll generate a tweet tagging key decisionmakers you can edit and send:</label>
        <div className='btn-wrap'>
          <input type='text' value={internalZip} className='form-control zip' onChange={evt => setInternalZip(evt.target.value)} />
          <ActionButton icon='twitter' disabled={internalZip.length !== 5 || fetchingZip} onClick={onClick}>{fetchingZip ? '...' : 'Tweet'}</ActionButton>
        </div>
      </div>
    }
  }
  return (
    <div className='tweet-wrap'>{show}</div>
  )
}
